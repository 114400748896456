import React, { useContext, useEffect, useState } from 'react';
import PageWrapperFluid from '../../Components/PageWrapperFluid';
import PageTitle from '../../Components/PageTitle';
import globalClasses from '../../App.module.css';
import AppTable from '../../Components/AppTable/index';
import { Table } from '@mantine/core';
import { AppContext } from '../../Context/AppContext';
import OrcImage from '../../Assets/boss/orc.png';
import MilgyoImage from '../../Assets/boss/milgyo.png';
import BossImage from '../../Assets/boss/boss.png';
import MetinsImage from '../../Assets/boss/metins.png';
import UndeadImage from '../../Assets/boss/undead.png';
import PvPImage from '../../Assets/boss/pvp.png';
import PvMImage from '../../Assets/boss/pvm.png';
import RankingImage from '../../Components/RankingImage';

const Ranking = () => {
  const [metins, setMetins] = useState([]);
  const [bosses, setBosses] = useState([]);
  const [players, setPlayers] = useState([]);
  const [pvps, setPvps] = useState([]);
  const { getCharClass, getEmpire, rankingQuests, rankingPlayers } =
    useContext(AppContext);

  useEffect(() => {
    if (rankingQuests && rankingPlayers) {
      let tops = [];
      rankingPlayers.map((char) => {
        tops.push({
          name: char.name,
          classe: getCharClass(char.job),
          lvl: char.level,
          reino: getEmpire(char.account?.index?.empire),
          elo: char.elo,
        });
      });
      let chars = [];
      rankingQuests
        .sort((a, b) => b.lValue - a.lValue)
        .filter((q) => q.szName === 'reputation_system')
        .filter((q) => q.szState !== '__status')
        .map((char) => {
          chars.push({
            name: char.player.name,
            classe: getCharClass(char.player.job),
            type: char.szState,
            kills: char.lValue,
            reino: getEmpire(char.player?.account?.index?.empire),
          });
          return char;
        });

      let metins = chars.filter((q) => q.type === 'rep_player');
      let insignia = chars.filter((q) => q.type === 'rep_insignia');
      let combined = [...insignia];
      // let combined = [...metins, ...insignia];

      let boss = chars.filter((q) => q.type === 'rep_boss');

      // let pvp = chars.filter((q) => q.type === 'rep_player');
      // let repPlayer = chars.filter((q) => q.type === 'rep_player').slice(0, 5);
      const somas = {};
      combined.forEach((objeto) => {
        const { name, kills, classe, reino } = objeto;
        if (!somas[name]) {
          somas[name] = {
            name: name,
            kills: kills,
            classe: classe,
            reino: reino,
          };
        } else {
          somas[name].kills += kills; // Somar os kills ao valor existente
        }
      });
      // boss.forEach((objeto) => {
      //   const { name, kills, classe, reino } = objeto;
      //   if (!somas[name]) {
      //     somas[name] = {
      //       name: name,
      //       kills: kills,
      //       classe: classe,
      //       reino: reino,
      //     };
      //   } else {
      //     somas[name].kills += kills; // Somar os kills ao valor existente
      //   }
      // });
      const repPvm = Object.values(somas);

      // setPvps(repPlayer);
      // setPvms(repPvm.sort((a, b) => b.kills - a.kills).slice(0, 5));

      setMetins(repPvm.sort((a, b) => b.kills - a.kills).slice(0, 10));
      setBosses(boss.slice(0, 10));
      console.log(tops);

      setPlayers(tops);
      // setPvps(pvp.slice(0, 10));
    }
  }, [rankingQuests, getCharClass, getEmpire]);

  const heads = ['Classe', 'Reino', 'Nome', 'Pontos'];
  const headsTop = ['Classe', 'Reino', 'Nome', 'Patente', 'Pontos'];

  const getPatente = (elo) => {
    let tier = '';
    let subTier = '';

    if (elo >= 6000) {
      tier = 'Soberano';
    } else if (elo >= 5000) {
      tier = 'Almirante';
    } else if (elo >= 4000) {
      tier = 'Capitão';
    } else if (elo >= 3000) {
      tier = 'Comandante';
    } else if (elo >= 2000) {
      tier = 'Tenente';
    } else if (elo >= 1000) {
      tier = 'Sargento';
    } else {
      tier = 'Recruta';
    }

    if (elo >= 0 && elo < 6000) {
      const baseElo = Math.floor(elo / 1000) * 1000;
      const subElo = Math.floor((elo - baseElo) / 200) + 1;

      switch (subElo) {
        case 1:
          subTier = ' I';
          break;
        case 2:
          subTier = ' II';
          break;
        case 3:
          subTier = ' III';
          break;
        case 4:
          subTier = ' IV';
          break;
        case 5:
          subTier = ' V';
          break;
        default:
          subTier = ' I';
      }
    } else if (elo > 6000) {
      subTier = '';
    }

    return tier + subTier;
  };

  const metinsRows = metins.map((element) => (
    <Table.Tr key={element.name}>
      <Table.Td>{element.classe}</Table.Td>
      <Table.Td>{element.reino}</Table.Td>
      <Table.Td>{element.name}</Table.Td>
      <Table.Td>{element.kills}</Table.Td>
    </Table.Tr>
  ));
  const bossRows = bosses.map((element) => (
    <Table.Tr key={element.name}>
      <Table.Td>{element.classe}</Table.Td>
      <Table.Td>{element.reino}</Table.Td>
      <Table.Td>{element.name}</Table.Td>
      <Table.Td>{element.kills}</Table.Td>
    </Table.Tr>
  ));
  const pvpRows = pvps.map((element) => (
    <Table.Tr key={element.name}>
      <Table.Td>{element.classe}</Table.Td>
      <Table.Td>{element.reino}</Table.Td>
      <Table.Td>{element.name}</Table.Td>
      <Table.Td>{element.kills}</Table.Td>
    </Table.Tr>
  ));
  const lvlRows = players.map((element) => (
    <Table.Tr key={element.name}>
      <Table.Td>{element.classe}</Table.Td>
      <Table.Td>{element.reino}</Table.Td>
      <Table.Td>{element.name}</Table.Td>
      <Table.Td>{getPatente(element.elo)}</Table.Td>
      <Table.Td>{element.elo}</Table.Td>
    </Table.Tr>
  ));

  return (
    <div style={{ width: '100%' }}>
      <PageTitle>Rankings</PageTitle>
      <PageWrapperFluid>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            className={globalClasses.rowItem}
            style={{
              width: 700,
            }}
          >
            <h2 style={{ textAlign: 'center' }}>Modo Ranqueado</h2>
            <RankingImage img={PvPImage} />
            <AppTable heads={headsTop} rows={lvlRows} />
          </div>
        </div>

        <div className={globalClasses.rowBox}>
          <div className={globalClasses.rowItem}>
            <h2 style={{ textAlign: 'center' }}>Top 10 Metins</h2>
            <RankingImage img={PvMImage} />
            <AppTable heads={heads} rows={metinsRows} />
          </div>
          <div className={globalClasses.rowItem}>
            <h2 style={{ textAlign: 'center' }}>Top 10 Boss</h2>
            <RankingImage img={MilgyoImage} />
            <AppTable heads={heads} rows={bossRows} />
          </div>
        </div>
      </PageWrapperFluid>
    </div>
  );
};

export default Ranking;
